<template>
    <div :class="{'mt-2': mode == 'dropdown'}">
        <h5 v-if="loading">Loading Notifications.. Please wait..</h5>
        <h6 class="text-center" v-else-if="results.length == 0">No Notifications</h6>
        <template v-else>
            <vx-card>
                <div slot="no-body">
                    <vs-list slot="no-body" class="notifications-list">
                        <template v-for="(item, index) in results">
                            <router-link :to="{name: 'notification-single', params: {id: item.id}}" :key="index">
                                <vs-list-item
                                    icon-pack="feather"
                                    :icon="getNotificationIcon(item)"
                                    :class="`text-${getNotificationColor(item)} ${item.is_read ? 'read' : ''}`"
                                    :title="item.rendered_title"
                                    :subtitle="item.rendered_body"
                                >
                                </vs-list-item>
                            </router-link>
                            <vs-divider v-if="index < results.length - 1" :key="`divider-${index}`" />
                        </template>
                    </vs-list>
                </div>
            </vx-card>
            <div class="mt-base" style="display: flex; align-items: center; justify-content: center;" v-if="mode != 'dropdown'">
                <vs-button color="primary" type="filled" size="small" class="text-center"  @click="load_more" v-if="next_url">Load More</vs-button>
            </div>
        </template>
    </div>
</template>

<script>
import { Request } from '@/utils/requests';

export default {
    props: {
        mode: {
            required: false,
            type:  String,
            default: 'page', // page or dropdown
        }
    },
    data() {
        return {
            results: [],
            api_errors: {},

            loading: false,
            total_results: null,
            next_url: null,
        }
    },
    mounted() {
        this.load_data()
    },
    methods: {
        load_data() {

            this.loading = true;
            let url = this.$store.state.apiendpoints.notifications

            Request(this, 'GET', url, {}).then(
                (res) => {
                    this.results = res.results
                    this.total_results = res.count
                    this.next_url = res.next;
                    this.loading = false;
                },
                (err) => {
                    this.loading = false;
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "Error while loading notification",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
        getNotificationIcon(item) {
            let iconMapping = {
                "compliance_upcoming": 'icon-clock',
                "compliance_due": 'icon-alert-triangle',
                "compliance_marked_today": 'icon-check-circle',
            }

            return iconMapping[item.notification_type] || 'bellIcon'
        },
        getNotificationColor(item) {
            let colorMapping = {
                "compliance_upcoming": 'warning',
                "compliance_due": 'danger',
                "compliance_marked_today": 'success',
            }

            return colorMapping[item.notification_type] || 'primary'
        }
    }
}
</script>

<style lang="scss">
    .notifications-list {
        padding: 0 !important;
        .vs-list {
            &--title {
                font-size: 0.8em;
            }
            &--subtitle {
                font-size: 0.7em !important;
            }
        }
        .vs-divider {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
</style>
